/* eslint-disable no-relative-import-paths/no-relative-import-paths */
import "../css/app.css";
import "./bootstrap";
import {createInertiaApp} from "@inertiajs/vue3";
import {createSSRApp, h} from "vue";
import {createGtm} from "@gtm-support/vue-gtm";
import {resolvePageComponent} from "laravel-vite-plugin/inertia-helpers";
import {createPinia} from "pinia";
import {ZiggyVue} from './plugins/ziggy';
import {Ziggy} from './ziggy';
import dayjs from "./plugins/dayjs";
import {createI18n} from "vue-i18n";
import localeMessages from "./vue-i18n-locales.generated";
import {flareVue} from "@flareapp/vue";
import flare from './plugins/flare';
import formKitTheme from "@/formkitTheme";
import {createAutoAnimatePlugin} from "@formkit/addons";
import {nl} from "@formkit/i18n";
import {arrowDown, arrowUp, check, circle, close, down, fileDoc, spinner, star, trash} from "@formkit/icons";
import {generateClasses} from "@formkit/themes";
import {defaultConfig as FKdc, plugin as FKp} from "@formkit/vue";
import Notifications from "notiwind";

const pinia = createPinia();

if (import.meta.env.PROD) {
    flare.light();
}

const numberFormats = {
    nl: {
        currency: {
            style: "currency",
            currency: "EUR",
        },

        percent: {
            style: "percent",
            useGrouping: true,
        },
    },
};

createInertiaApp({
    progress: {
        delay: 250,
        color: "#EEB717",
    },
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob("./Pages/**/*.vue")),
    setup({el, App, props, plugin}) {
        const currentLocale = props.initialPage.props.locale;

        const i18n = createI18n({
            numberFormats,
            legacy: false,
            locale: currentLocale,
            fallbackLocale: "en",
            messages: localeMessages,
        });

        return createSSRApp({render: () => h(App, props)})
            .use(plugin)
            .use(pinia)
            .use(flareVue)
            .use(
                createGtm({
                    id: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID,
                    enabled: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ENABLED === 'true' && import.meta.env.PROD,
                    debug: import.meta.env.NODE_ENV !== 'production',
                })
            )
            .use(ZiggyVue, {...Ziggy, locale: currentLocale})
            .use(dayjs, {locale: currentLocale})
            .use(i18n)
            .use(Notifications)
            .use(
                FKp,
                FKdc({
                    config: {
                        classes: generateClasses(formKitTheme),
                    },
                    plugins: [createAutoAnimatePlugin({duration: 150})],
                    locales: {nl},
                    locale: "nl",
                    globalInjection: true,
                    icons: {
                        // include supporting icons from @formkit/icons
                        arrowDown,
                        arrowUp,
                        close,
                        checkboxDecorator: check,
                        fileItem: fileDoc,
                        fileRemove: close,
                        noFiles: fileDoc,
                        radioDecorator: circle,
                        select: down,
                        spinner,
                        star,
                        trash,
                    },
                })
            )
            .mount(el);
    },
})
